body {
  margin: 0;
  font-family: Mitr, sans-serif !important;
}

.MuiAppBar-root {
  height: 64px;
  justify-content: center;
}
.MuiChip-root {
  height: 26px !important;
}
.MuiTextField-root {
  height: 76px;
}
.MuiTextField-root.MuiFormControl-marginDense {
  margin-top: 0;
  height: 60px;
}
.MuiFormLabel-root {
  color: #8e8e8e !important;
}
.MuiIconButton-colorPrimary {
  color: #00acea !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #00acea !important;
}
.MuiFormLabel-root.Mui-error {
  color: #f44336 !important;
}
.MuiFormHelperText-root.MuiFormHelperText-contained {
  margin: 0 !important;
  min-height: 20px;
}
.MuiButton-containedPrimary:hover {
  color: #ffffff;
  background-color: #0089bb !important;
}
.MuiButton-containedSecondary:hover {
  color: #ffffff;
  background-color: #3b3b3b !important;
}
.MuiOutlinedInput-adornedEnd {
  padding: 0 !important;
}
.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 14px;
  font-weight: 300;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #00acea99 !important;
  color: #ffffff;
}
.MuiListItem-button:hover {
  background-color: #00acea99 !important;
  color: #ffffff;
}

#container-layout::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
#container-layout::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
}

.action-button.MuiButton-root {
  font-weight: 400;
}
.input-add-on.MuiInputAdornment-root {
  width: 48px;
  height: 40px;
  max-height: 40px;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00acea;
}
.large-input-add-on.MuiInputAdornment-root {
  width: 52px;
  height: 54px;
  max-height: 54px;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00acea;
}
.report-form .MuiInputBase-input,
.report-form .MuiSelect-selectMenu {
  height: 30px !important;
  padding: 5px;
}
.report-form.MuiFormControl-marginDense {
  margin-bottom: 0 !important;
}

.fill-height {
  height: 100%;
}
.fill-width {
  width: 100%;
}
.border-all {
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.border-error-all {
  border: 1px solid #f44336;
}
.border-radius-all {
  border-radius: 4px;
}
.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}
.text-right {
  text-align: right !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-primary-drop {
  color: rgb(28, 28, 28, 0.8) !important;
}
.text-primary-bold-drop {
  color: rgb(28, 28, 28, 0.5) !important;
}
.text-secondary-drop {
  color: rgb(255, 255, 255, 0.8) !important;
}
.text-secondary-bold-drop {
  color: rgb(255, 255, 255, 0.6) !important;
}
.text-success {
  color: #4caf50;
}
.text-warning {
  color: #ff9800;
}
.text-error {
  color: #f44336;
}
.required-label::after {
  content: '*';
  color: #ff0000;
}

.locale-menu .MuiListItem-root.MuiMenuItem-root {
  font-size: 12px;
  font-weight: 300;
  text-transform: none;
  color: #4e4e4e;
}
.topbar-locale-menu .MuiListItem-root.MuiMenuItem-root,
.topbar-user-menu .MuiListItem-root.MuiMenuItem-root {
  font-size: 14px;
  font-weight: 300;
  text-transform: none;
  color: #4e4e4e;
}

.topbar-locale-menu .MuiListItem-root.Mui-selected,
.topbar-user-menu .MuiListItem-root.Mui-selected,
.locale-menu .MuiListItem-root.Mui-selected {
  color: #00acea;
  background-color: #ffffff;
}
.topbar-user-menu .MuiListItem-root.Mui-selected:hover,
.topbar-user-menu .MuiListItem-root:hover,
.topbar-locale-menu .MuiListItem-root.Mui-selected:hover,
.topbar-locale-menu .MuiListItem-root:hover,
.locale-menu .MuiListItem-root.Mui-selected:hover,
.locale-menu .MuiListItem-root:hover {
  color: #ffffff;
  background-color: #00acea;
}

.topbar .user-button.MuiButton-root,
.topbar .locale-button.MuiButton-root {
  font-weight: 400;
  color: #ffffff;
  text-transform: none;
}

.primary-button {
  background-color: #00acea !important;
  color: #ffffff !important;
}
.primary-button:hover {
  background-color: #0089bb !important;
  color: #ffffff !important;
}
.primary-button.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.edit-button {
  background-color: #ff9800 !important;
  color: #ffffff !important;
}
.edit-button:hover {
  background-color: #f57c00 !important;
  color: #ffffff !important;
}
.edit-button.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.error-button {
  background-color: #f44336 !important;
  color: #ffffff !important;
}
.error-button:hover {
  background-color: #d32f2f !important;
  color: #ffffff !important;
}
.error-button.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.error-button.MuiButton-outlined {
  border: 1px solid #f44336 !important;
  background-color: #ffffff !important;
  color: #f44336 !important;
}
.error-button.MuiButton-outlined:hover {
  border: 1px solid #f44336 !important;
  background-color: rgba(244, 67, 54, 0.1) !important;
  color: #f44336 !important;
}
.error-button.MuiButton-outlined.Mui-disabled {
  border: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
}

.success-button {
  background-color: #4caf50 !important;
  color: #ffffff !important;
}
.success-button:hover {
  background-color: #3c8c40 !important;
  color: #ffffff !important;
}
.success-button.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.info-button {
  background-color: #115293 !important;
  color: #ffffff !important;
}
.info-button:hover {
  background-color: #0d4175 !important;
  color: #ffffff !important;
}
.info-button.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.map-marker:hover svg {
  fill: #f44336;
}

.image-upload-container .fileContainer {
  box-shadow: none;
}
.image-upload-container .fileContainer .deleteImage {
  background: #f44336;
}

.date-picker-input.MuiTextField-root {
  width: 100%;
}
.no-detail-input.MuiTextField-root {
  height: auto;
}

.dropzone-container {
  border-width: 2px;
  border-radius: 4px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  transition: border 0.24s ease-in-out;
}
.dropzone-container:focus {
  border-color: #00acea;
}
.uploaded-container {
  border-width: 2px;
  border-radius: 4px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #00acea;
  min-height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.import-header-card {
  border-width: 1px;
  border-color: #4a4a4a99;
  border-style: solid;
  border-radius: 4px;
  color: #4a4a4a;
  background-color: #ffffff;
  min-height: 30px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  transition: background-color 0.2s ease;
}

.list-header {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #4a4a4a;
}

.employee.stats-card {
  background: linear-gradient(to top, #4cc4f0 0%, #00acea 100%);
}
.company.stats-card {
  background: linear-gradient(to top, #ffb64c 0%, #ff9800 100%);
}
.active.stats-card {
  background: linear-gradient(to top, #81c784 0%, #4caf50 100%);
}
.inactive.stats-card {
  background: linear-gradient(to top, #f77b72 0%, #f44336 100%);
}
.license.stats-card {
  background: linear-gradient(to top, #1979d9 0%, #115293 100%);
}
.stats-card .MuiDivider-root {
  background-color: #ffffff44;
}

.dashboard-welcome-container {
  border-radius: 16px !important;
  min-height: 500px;
  background: linear-gradient(to top, #4cc4f0 0%, #00acea 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}
